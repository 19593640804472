import { Border, Color, Row } from 'atomic'
import styled from 'styled-components'

export const ArrowUp = styled.div`
  width: 16px;
  height: 16px;
  top: -8px;
  background-color: ${Color.Neutral};

  transform: rotate(45deg);
  position: absolute;
  border-left: ${Border.Width} solid ${Border.Color};
  border-top: ${Border.Width} solid ${Border.Color};
`

export const AnimatedRow = styled(Row)`
  height: 100%;
`

export const MensagemPlanoVerificar = styled.span`
    background-color: #FEF6E7;
    padding: 25px 10px;
    border-radius: 8px;
    margin: 10px 0;
    display: flex;
    justify-content: center;
    align-items: center;

    p{
      color: #595959;
      font-size: 12px;
    }
    svg{
      margin: 0 20px 0 0px;

    }

    a{
      color:#595959;
    }
`

export const NeutralBackgroundNoGutter = styled.div`
  width: 100%;
  position: relative;

  :before {
    width: 100vw;
    height: 100%;
    left: 50%;
    transform: translateX(-50%);
    position: absolute;
    display: block;
    border-top: ${Border.Width} solid ${Border.Color};
    border-bottom: ${Border.Width} solid ${Border.Color};
    content: '';
    background-color: ${Color.Neutral};
  }
  div {
    position: relative;
  }
`
