import { OverflowXHiddenWrapper } from '@app/components/atm.wrapper/wrapper.component'
import { SEO } from '@app/components/legacy/mol.seo/seo.component'
import { ClientDoctorSelectContext } from '@app/context/client-doctor-select'
import { PageProps } from '@app/utils/local-types'
import { appPaths, getCanonicalUrl } from 'utils/path'
import { getClientAndDoctorUrl } from 'utils/url'
import LargeSeparatorRow from 'atomic/atm.large-separator-row/large-separator-row.component'
import { Breadcrumb } from '@root/src/components/atm.breadcrumb/breadcrumb.component'
import IndexLayout from '@root/src/components/org.layout/layout.component'
import { Query } from '@root/src/data/graphql/graphql-types'
import ConvenioDetailRow from '@root/src/modules/health-plan/component/convenio-detail-row.component'
import { PlanoConvenio } from '@root/src/modules/health-plan/component/convenios-accordion.component'
import { Body, Button, Col, Divisor, Grid, GridSettings, H1, H2, H3, Row, Separator } from 'atomic'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { graphql } from 'gatsby'
import React, { useEffect } from 'react'

export interface HealthPlanDetailTemplateProps {
  slug: string
  genericDetail: boolean
}

interface Contact {
  description: string
  phone: string
}

interface PageData {
  title: string
  col1Title: string
  contentHTML: string
  col2Title: string
  contactTitle: string
  contactHTML: string
  contactHealthPlanTitle: string
  contacts: Contact[]
}

const HealthPlanDetailTemplate: React.FunctionComponent<PageProps<
  HealthPlanDetailTemplateProps,
  Query
>> = props => {
  const isGeneric = props.pageContext.genericDetail
  const detalheGeral = props.data.cosmicjsInformacoesEstaticas.metadata.detalheGeral
  const detail = isGeneric ? detalheGeral.metadata : props.data.convenios.detalhe
  const cosmicStaticInfo = props.data.cosmicjsInformacoesEstaticas
  const seoData = detail.seo
  const breadcrumbAdditionalDictionary = {}
  const planos: PlanoConvenio[] = isGeneric
    ? []
    : props.data?.convenios.planos.map(plano => ({ name: plano.descricao }))
  const pageData: PageData = {
    title: isGeneric ? detalheGeral.title : props.data?.convenios.descricao,
    col1Title: cosmicStaticInfo.metadata.col1Titulo,
    contentHTML: detail.informacoes,
    col2Title: cosmicStaticInfo.metadata.col2Titulo,
    contactTitle: cosmicStaticInfo.metadata.contatoFleuryTitulo,
    contactHTML: cosmicStaticInfo.metadata.conteudoContato,
    contactHealthPlanTitle: cosmicStaticInfo.metadata.contatoConvenioTitulo,
    contacts: detail.telefones.map(phone => ({ description: phone.descricao, phone: phone.numero }))
  }
  const [showDetailRow, setShowDetailRow] = React.useState(false)
  const handleClick = () => {
    return setShowDetailRow(!showDetailRow)
  }

  breadcrumbAdditionalDictionary[`/${props.pageContext.slug}`] = pageData.title

  const [clientPath, doctorPath] = getClientAndDoctorUrl(props.location)

  return (
    <ClientDoctorSelectContext.Provider value={{ clientUrl: clientPath, doctorUrl: doctorPath }}>
      <IndexLayout location={props.location}>
        <SEO
          socialMedia={{
            canonicalUrl: getCanonicalUrl(
              props.data.site.siteMetadata.siteUrl,
              appPaths.convenio.path,
              props.pageContext.slug
            ),
            title: seoData.titulo,
            image: seoData.imagem.url,
            imageAlt: seoData.imagemAlternativa,
            description: seoData.descricao
          }}
        />
        <OverflowXHiddenWrapper>
          <Grid>
            <Row>
              <Col xs={12}>
                <Separator />
                <Breadcrumb addtionalDictionary={breadcrumbAdditionalDictionary} />
              </Col>
            </Row>
            <Row mt middle="xs" mb>
              <Col xs={12} md={8}>
                <H1>{pageData.title}</H1>
              </Col>
              {planos.length > 0 && (
                <Col xs={12} md={4}>
                  <Button id="button-see-plans" kind="secondary" expanded onClick={handleClick}>
                    Ver Planos
                  </Button>
                </Col>
              )}
            </Row>
            {planos.length > 0 && showDetailRow && (
              <>
                <MatchMedia defaultMinWidth={`${GridSettings.flexboxgrid.breakpoints.md}em`}>
                  {md =>
                    md ? (
                      <ConvenioDetailRow
                        arrowColSize={12}
                        arrowOffset={0}
                        listaDePlanos={planos}
                        onClose={handleClick}
                      />
                    ) : (
                      <ConvenioDetailRow
                        arrowColSize={4}
                        arrowOffset={8}
                        listaDePlanos={planos}
                        onClose={handleClick}
                      />
                    )
                  }
                </MatchMedia>
              </>
            )}
            <Row>
              <Col xs={12} md={7}>
                <H2>{pageData.col1Title}</H2>
                <Separator />
                <Body>
                  <div dangerouslySetInnerHTML={{ __html: pageData.contentHTML }} />
                </Body>
              </Col>

              <Col xs={12} mdOffset={1} md={4}>
                <H2>{pageData.col2Title}</H2>
                <Separator />
                <H3>{pageData.contactTitle}</H3>
                <Body>
                  <div dangerouslySetInnerHTML={{ __html: pageData.contactHTML }} />
                </Body>
                <Separator />
                {pageData.contacts[0].phone.length > 0 && (
                  <>
                    <Divisor />
                    <Separator />
                    <H3>{pageData.contactHealthPlanTitle}</H3>
                    {pageData.contacts.map((contact, index) => (
                      <React.Fragment key={index}>
                        <Body>{contact.description}</Body>
                        <Button id="button-health-plan-phone" kind="link">
                          {contact.phone}
                        </Button>
                        <Separator />
                      </React.Fragment>
                    ))}
                  </>
                )}
              </Col>
            </Row>

            <LargeSeparatorRow />
          </Grid>
        </OverflowXHiddenWrapper>
      </IndexLayout>
    </ClientDoctorSelectContext.Provider>
  )
}

export default HealthPlanDetailTemplate

export const query = graphql`
  query HealthPlanDetailbySlug($slug: String!) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    convenios(detalhe: { slug: { eq: $slug } }) {
      descricao
      planos {
        descricao
      }
      detalhe {
        informacoes
        seo {
          titulo
          descricao
          imagemAlternativa
          imagem {
            url
          }
        }
        telefones {
          numero
          descricao
        }
      }
    }
    cosmicjsInformacoesEstaticas(slug: { eq: "detalhe-do-convenio" }) {
      metadata {
        col1Titulo
        col2Titulo
        contatoFleuryTitulo
        conteudoContato
        contatoConvenioTitulo
        detalheGeral {
          title
          metadata {
            informacoes
            seo {
              titulo
              descricao
              imagemAlternativa
              imagem {
                url
              }
            }
            telefones {
              numero
              descricao
            }
          }
        }
      }
    }
  }
`
