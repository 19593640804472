import { Body, Button, Col, Color, FaIcon, H3, Row, Separator } from 'atomic'
import { MatchMedia } from 'atomic/legacy/obj.match-media'
import { RelativeWrapper } from 'atomic/obj.wrappers'
import * as React from 'react'
import {
  FieldsWrapper,
  MultipleCollumnWrapper
} from '../../../components/atm.wrapper/wrapper.component'
import { IndexParams } from '../../../components/mol.local-search/fuse-search.utils'
import { PlanSearch } from '../../../components/mol.local-search/plan-search.component'
import {
  AnimatedRow,
  ArrowUp,
  MensagemPlanoVerificar,
  NeutralBackgroundNoGutter
} from './convenio-detail-row.style'
import { PlanoConvenio } from './convenios-accordion.component'

import SvgAlertConvenio from 'atomic/atm.svg-icon/alert-convenios-icon'

interface IConvenioDetailRowProps {
  arrowColSize: number
  arrowOffset: number
  listaDePlanos: PlanoConvenio[]
  listaDePlanosDestaques: PlanoConvenio[]
  detailSlug?: string
  onClose?: () => any
}

const staticInfoMap = {
  searchFieldText: 'Planos Atendidos',
  searchFieldPlaceholder: 'Digite seu plano',
  searchButtonText: 'Ver mais informações'
}
const ConvenioDetailRow: React.FunctionComponent<IConvenioDetailRowProps> = props => {
  const [items, setItems] = React.useState(props.listaDePlanos)
  const [itemsDestaques, setItemsDestaques] = React.useState(props.listaDePlanosDestaques)
  const onResult = list => {
    if (list) {
      setItems(list)
    }
  }
  const indexParams: IndexParams<PlanoConvenio> = {
    data: props.listaDePlanos,
    keys: [
      {
        name: 'name',
        weight: 1
      }
    ]
  }

  if (!items) {
    return null
  }

  React.useEffect(() => {
    console.log('props aqui pai', props)
  }, [props])

  return (
    <AnimatedRow mb>
      <NeutralBackgroundNoGutter>
        <RelativeWrapper>
          <Col xs={12}>
            <Row>
              <Col xs={props.arrowColSize} xsOffset={props.arrowOffset}>
                <Row center="xs">
                  <Col>
                    <ArrowUp />
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row mb>
              <Col xs={12}>
                <Row>
                  <Col xs={11} md={2}>
                    <H3>{staticInfoMap.searchFieldText}</H3>
                  </Col>

                  <Col xs={1} md={false}>
                    <Row center="xs" middle="xs" expanded>
                      <Col>
                        <FaIcon.Close size="lg" color={Color.GrayDark} onClick={props.onClose} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={6}>
                    <FieldsWrapper>
                      <PlanSearch
                        indexParams={indexParams}
                        onResultChange={onResult}
                        placeholder={staticInfoMap.searchFieldPlaceholder}
                      />
                    </FieldsWrapper>
                  </Col>

                  {props.detailSlug && (
                    <Col xs={12} md={3}>
                      <MatchMedia defaultMinWidth="64em">{md => md && <Separator />}</MatchMedia>
                      <Button
                        kind="secondary"
                        expanded
                        to={props.detailSlug}
                        id={'test-company-detail-button'}
                      >
                        {staticInfoMap.searchButtonText}
                      </Button>
                    </Col>
                  )}

                  <Col xs={false} mdOffset={props.detailSlug ? 0 : 3} md={1}>
                    <Row center="xs" middle="xs" expanded>
                      <Col>
                        <FaIcon.Close size="lg" color={Color.GrayDark} onClick={props.onClose} />
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} md={12}>
                    {props?.convenio?.name?.toLowerCase().includes('unimed') && (
                      <MensagemPlanoVerificar>
                        <SvgAlertConvenio/>
                         <p>Este convênio não cobre todas as unidades. Para saber quais unidades atendem seu convênio, <strong><a href='https://api.whatsapp.com/send?phone=555140043080'>fale com a gente</a>.</strong></p>
                      </MensagemPlanoVerificar>
                    )}
                  </Col>
                  <Col xs={12} md={12}>
                    <Separator />
                    {itemsDestaques.length > 0 && (
                      <>
                        <H3>Planos mais utilizados</H3>
                        <MultipleCollumnWrapper id="container-list-plans" numberOfCollumns={3}>
                          {itemsDestaques.map((plano, index) => (
                            <Body id={`item-list-plans-${index}`} key={`${index} - ${plano.name}`}>
                              {plano.name}
                            </Body>
                          ))}
                        </MultipleCollumnWrapper>
                        <H3>Outros planos</H3>
                      </>
                    )}
                    <MultipleCollumnWrapper id="container-list-plans" numberOfCollumns={3}>
                      {items.map((plano, index) => (
                        <Body id={`item-list-plans-${index}`} key={`${index} - ${plano.name}`}>
                          {plano.name}
                        </Body>
                      ))}
                    </MultipleCollumnWrapper>
                    <Separator />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </RelativeWrapper>
      </NeutralBackgroundNoGutter>
    </AnimatedRow>
  )
}

export default ConvenioDetailRow
