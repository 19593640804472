// tslint:disable:no-console
import { TextField } from 'atomic/legacy/atm.text-field'
import { Form } from 'atomic/legacy/obj.form'
import { debounceWithLimit } from 'utils/debounce/debounce'
import React, { useEffect } from 'react'
// import Worker from 'workerize-loader?inline!./fuse-search.worker'
import worker from 'workerize-loader!./fuse-search.worker'
import { IndexParams } from './fuse-search.utils'

export interface PlanSearchProps<D> {
  indexParams: IndexParams<D>
  onResultChange: (result: D) => any
  label?: string
  placeholder?: string
}

export const PlanSearch: React.FunctionComponent<PlanSearchProps<any>> = props => {
  const searchWorker = React.useRef<{ search: Function; buildIndex: Function }>()
  const searchData = React.useCallback(
    (searchValue: string) => {
      if (searchValue.length > 0) {
        searchWorker.current.search(searchValue).then(result => {
          props.onResultChange(result)
        })
      } else {
        props.onResultChange(props.indexParams.data)
      }
    },
    [searchWorker.current]
  )
  const handleSubmit = React.useCallback(
    data => {
      searchData(data.data.search)
    },
    [searchData]
  )
  useEffect(() => {
    searchWorker.current = new worker() as any
    searchWorker.current.buildIndex(props.indexParams).then(() => {
      debouncedSearch.current = debounceWithLimit(searchData, 3)
    })
  }, [])

  const debouncedSearch = React.useRef<any>()
  const onValueChange = React.useCallback(value => {
    debouncedSearch.current(value)
  }, [])

  return (
    <>
      <Form id="form-plan-search" onSubmit={handleSubmit}>
        <Form.Field name="search" label={props.label} onValueChange={onValueChange}>
          <TextField placeholder={props.placeholder} id={'test-plan-search'} />
        </Form.Field>
      </Form>
    </>
  )
}
